import Head from 'next/head'
import styled from 'styled-components'
import Router, { useRouter } from 'next/router'
import dynamic from 'next/dynamic'

const Cookies = dynamic(() => import('components/Cookies'))
// import Cookies from 'components/Cookies'
// import params from 'utils/params'



Router.events.on('routeChangeComplete', url => {
    if (window.analytics) {
        window.analytics.page(url)
    }
})

export default function Layout(props) {
    const router = useRouter()
    const { asPath } = router

    const site = 'https://vitalhabits.app'
    const canonicalURL = site + asPath.split('?')[0]
    const isArticle = router.pathname === '/articles/[slug]'

    // if (params.openWaitlist && params.hiddenPages.includes(asPath)) {
    //     return null
    // }

    return (
        <>
            <Head>
                <link rel="canonical" href={canonicalURL} />
                <meta property="og:url" content={canonicalURL} />
                <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0" />
                <meta property="og:type" content="website" />
                <meta property="fb:app_id" content="4754414534676428" />
                <meta name="language" content="en" />
                <meta name="dc.language" content="en" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <link rel="shortcut icon" href="/favicon.ico" />
                <link rel="manifest" href="/manifest.json" />
                <meta name="msapplication-config" content="/browserconfig.xml" />
                <meta name="msapplication-TileColor" content="#C8FE59" />
                <meta name="theme-color" content="#C8FE59" />
            </Head>
            <Container isArticle={isArticle}>
                <Cookies />
                <main>{props.children}</main>
            </Container>
        </>
    )
}

const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    ${props => (props.isArticle ? '' : 'position: relative;  overflow: hidden;')}
`
